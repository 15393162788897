import { useQuery } from "@tanstack/react-query";
import { client } from "../utils/axios-utils";

export const getEnv = () => {
  return client().get<{
    data: { data: { environment: string; version: string } };
  }>(`v2/env`);
};

export const useGetEnv = () =>
  useQuery({
    queryFn: getEnv,
    queryKey: ["env"],
    staleTime: Infinity,
    select: (data) => data.data.data.data,
  });
