"use client";
import npmPackage from "../../../package.json";

import { datadogRum } from "@datadog/browser-rum";
import { getEnv } from "@queries/getEnv";
import { useEffect } from "react";
import { setItem } from "@utils/localStorage";

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      getEnv().then((res) => {
        console.log(`
Name: ${npmPackage.name}
Current version: ${process.env.NEXT_PUBLIC_VERSION}
Env: ${res.data.data.data.environment}
`);

        setItem("app_version", process.env.NEXT_PUBLIC_VERSION!);

        datadogRum.init({
          allowedTracingUrls: [
            "http://localhost:3000",
            "https://media-api.staging.olyn.com",
            "https://media-api.demo.olyn.com",
            "https://media-api.olyn.com",
            "https://media.staging.olyn.com",
            "https://media.demo.olyn.com",
            "https://media.olyn.com",
          ],
          applicationId: "d8b14773-e727-4693-b28b-c6ca0ecc5cac",
          clientToken: "pub7f0dfdd6e91b67fad726f2bff070f282",
          site: "datadoghq.com",
          service: npmPackage.name,
          env: res.data.data.data.environment ?? "localhost",
          version: process.env.NEXT_PUBLIC_VERSION! ?? "0.0.0",
          sessionSampleRate: 100,
          sessionReplaySampleRate: 100,
          storeContextsAcrossPages: true,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: "allow",
        });

        datadogRum.startSessionReplayRecording();
      });
    }
  }, []);

  return <>{children}</>;
};

export default Layout;
